.profile-card {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 40px;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		207.26deg,
		#222222 4.71%,
		#111111 91.04%,
		#292a2d 100%
	);
	@media screen and (max-width: 1920px) {
		padding: 20px;
	}
}
