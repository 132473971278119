.metro {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	text-transform: capitalize;
	font-family: var(--ubuntu);
	line-height: 1em;
	font-weight: 500;
	min-width: 0;
	&[data-color='white'] {
		> svg {
			color: white;
		}
	}
	> svg {
		color: #7e7e7e;
		min-width: 24px;
		height: 24px;
		width: 24px;
	}
}
