.hamburger {
	display: grid;
	place-content: center;
	z-index: 5;
	position: relative;

	&:after,
	&:before {
		content: '';
		position: absolute;
		top: 10.5px;
		height: 0;
		border-bottom: 3px solid var(--white-to-ocean);
		width: 24px;
		left: 8px;
		transition: all ease-out 0.3s;
	}

	&:after {
		top: 27.5px;
	}

	i {
		display: block;
		text-indent: 100%;
		overflow: hidden;
		white-space: nowrap;
		height: 3px;
		background-color: var(--white-to-ocean);
		width: 24px;
		position: absolute;
		left: 8px;
		top: 19px;
		transition: all ease-out 0.1s;
	}

	&.active {
		&:after {
			transform: rotate(-45deg);
			transform-origin: center;
			top: 19px;
		}

		&:before {
			transform: rotate(45deg);
			transform-origin: center;
			top: 19px;
		}

		i {
			opacity: 0;
		}
	}
}
