.profile-workouts {
	flex-grow: 1;
	height: 50%;
	@media screen and (max-width: 1024px) {
		height: auto;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		&__title {
			font-size: 18px;
			font-weight: 500;
		}
		&__link {
			display: flex;
			gap: 5px;
			align-items: center;
			color: var(--green);
			font-size: 12px;
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
		gap: 15px;
		&__item {
			display: flex;
			justify-content: space-between;
			> span:last-child {
				font-weight: 600;
			}
		}
	}
}
