@import 'src/app/styles/breakpoints';
.level-info {
	display: flex;
	align-items: center;
	min-width: 0;
	gap: 5px;
	font-size: 1.25em;
	line-height: 1em;
	font-weight: 500;
	&__icon {
		fill: var(--level-color);
		color: var(--level-color);
		min-width: 24px;
		height: 24px;
		width: 24px;
	}
}
