@import 'src/app/styles/breakpoints';
.switcher {
	display: flex;
	color: var(--white);
	font-size: 1rem;
	gap: 2rem;
	justify-content: space-between;
	&__date {
		min-width: 130px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__arrow {
		&__icon {
			fill: var(--white);
		}
	}
	> button {
		font-weight: 500;
		transition: opacity 150ms ease-in;
		&:hover {
			opacity: 60%;
		}
	}
	@include lg {
		font-size: 0.7em;
		gap: 0.5rem;
		&__date {
			min-width: 95px;
		}
	}
	@include md {
		width: 100%;
		font-size: 1.25rem;
	}
}
