.profile-settings-contacts {
	@media screen and (max-width: 1024px) {
		grid-area: contacts;
	}
	&__title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 10px;
	}
}
