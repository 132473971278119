@import 'src/app/styles/breakpoints';
.schedule {
	--filters-size: 0px;
	color: var(--white);
	font-family: var(--font-primary);
	background-color: var(--black);
	background-color: var(--main-bg);
	padding-bottom: 60px;
	&__overlay {
		@include md {
			position: fixed;
			top: 0;
			right: 0;
			width: calc(100%);
			height: 100%;
			z-index: 100;
			background-color: rgba(0, 0, 0, 0.6);
			transition: opacity 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
			pointer-events: none;
			opacity: 0;

			&.filters-open {
				opacity: 1;
				pointer-events: all;
				backdrop-filter: blur(5px);
			}
		}
	}
	&__content {
		position: relative;
		transition: grid-template-columns 0.3s ease-in-out;
		min-height: calc(
			100vh - var(--global-header-height) - var(--global-footer-height)
		);
		padding-bottom: 40px;
		display: grid;
		grid-template-columns: var(--filters-size) 1fr;
		&.filters-open {
			--filters-size: 270px;
			@include hd {
				gap: 20px;
			}
		}
		@include xxxl {
			display: flex;
			flex-direction: column;
			padding-inline: 0;
		}
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
