.workout {
	color: #fff;
	padding-top: 20px;
	padding-bottom: 40px;
	position: relative;
	max-width: 1440px;
	padding: 0 var(--main-x-padding);
	&__content {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		@media screen and (max-width: 1023px) {
			flex-direction: column;
		}
		&__time {
			font-size: 20px;
			display: flex;
			padding: 0 5px;
			align-items: center;
			line-height: 25px;
			justify-content: space-between;
			gap: 10px;

			@media screen and (max-width: 768px) {
				font-size: 16px;
			}
			&__date {
				display: flex;
				align-items: center;
				gap: 10px;
				font-weight: 700;
				> svg {
					height: 20px;
					aspect-ratio: 1 / 1;
					width: 20px;
				}
			}
			&__interval {
				font-weight: 700;
				line-height: 25px;
				> svg {
					height: 20px;
					width: 20px;
					aspect-ratio: 1 / 1;
				}
			}
		}

		&__main {
			width: 100%;
			@media screen and (min-width: 1024px) {
				max-width: 60%;
			}
			display: flex;
			flex-direction: column;
			gap: 20px;
			&__slider {
				width: 100%;

				&__container {
					border-radius: 20px;
				}
			}
			&__title {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				font-weight: 700;
				max-height: 45px;
				> h1 {
					font-size: 40px;
					text-transform: uppercase;
				}
			}
		}
		&__secondary {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			gap: 20px;
			width: 100%;
			@media screen and (min-width: 1024px) {
				width: 40%;
			}
		}
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100dvh;
		width: 100vw;
		z-index: 70;
		background-color: #000000a2;
		display: flex;
		justify-content: center;
		align-items: center;
		&__content {
			display: flex;
			flex-direction: column;
			height: 70vh;
			width: 90vw;
			max-height: 600px;
			border-radius: 10px;
			max-width: 1024px;
			@media screen and (max-width: 1023px) {
				max-height: 500px;
			}
			@media screen and (max-width: 768px) {
				max-height: 400px;
				width: 100vw;
			}
			&__video {
				width: 100%;
				height: 100%;
				position: relative;
				&__close {
					position: absolute;
					top: 15px;
					right: 15px;
					z-index: 20;
				}
				> iframe {
					border-radius: 10px;

					border: none;
					&:focus {
						outline: none;
					}
				}
			}
		}
	}
	&__scrollToButton {
		position: fixed;
		bottom: 20px;
		right: 100px;
		z-index: 35;
		width: 170px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
