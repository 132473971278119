.profile-history {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 14px;
	@media screen and (max-width: 1024px) {
		font-size: 12px;
	}

	&__card {
		max-height: 650px;
		padding: 10px;
		@media screen and (max-width: 1440px) {
			padding: 5px;
		}
		@media screen and (max-width: 768px) {
			max-height: 600px;
		}
	}
	&__header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
		align-items: center;
		&__buttons {
			display: flex;
			gap: 20px;
			@media screen and (max-width: 768px) {
				gap: 10px;
			}
			&__btn {
				background-color: #1a1a1a80;
				width: 170px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	&__list {
		&__wrapper {
			overflow: auto;
		}
		padding: 30px;
		@media screen and (max-width: 1440px) {
			padding: 15px;
		}
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(auto-fit, minmax(0px, 1fr));
		gap: 30px;
	}
	&__text {
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media screen and (max-width: 768px) {
			flex-wrap: wrap;
			gap: 10px;
		}
		&__link {
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			white-space: nowrap;
			> svg {
				width: 18px;
				height: 18px;
			}
		}
	}
}
.rate-modal {
	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	&__title {
		font-size: 24px;
	}
}
