ul,
ol {
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  background-color: transparent;
  padding: 0;
  border: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// input[type='number'] {
//   -moz-appearance: textfield;
// }

a{
  text-decoration: none;
}