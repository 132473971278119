.secondary-info {
	font-size: 14px;
	@media screen and (max-width: 1024px) {
		grid-area: secondary;
	}
	&__card {
		@media screen and (max-width: 1024px) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
		&__avatar-wrapper {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}
	&__avatar {
		width: 100%;
		aspect-ratio: 1 / 1;
		border-radius: 12px;
		object-fit: cover;
		@media screen and (max-width: 768px) {
			max-height: 400px;
		}

		&-label {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
	&__control {
		display: flex;
		flex-direction: column;
		gap: 10px;
		&__change-button {
			margin: 10px 0;
			border: 1px solid var(--white);
			border-radius: 5px;
			cursor: pointer;
			width: 100%;
			font-size: 12px;
			transition: all 0.3s ease-in-out;
			padding: 10px;
			text-align: center;
			color: var(--green);
			&:hover {
				opacity: 0.8;
			}
			&__label {
				padding-right: 5px;
			}
			&__icon {
				position: relative;
				top: 1.5px;
			}
		}
		&__input-file {
			display: none;
		}
		&__about {
			color: var(--white);
			min-height: 150px;
			z-index: 2;
			width: 100%;
			background-color: var(--graphite);
			padding: 10px 15px;
			border-radius: 5px;
			border: 1px solid #ffffff80;
			resize: none;
			&:focus {
				background-color: #18191b;
			}
			&::placeholder {
				opacity: 0.6;
			}
		}
	}
}
