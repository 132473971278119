@import 'src/app/styles/breakpoints';
.schedule-week {
	&__weekdays {
		--weekday-size: 130px;
		display: grid;
		grid-template-columns: repeat(7, minmax(130px, 1fr));
		align-items: center;
		justify-content: center;
		width: 100%;
		z-index: 6;
		padding-block: 8px;
		background-color: var(--main-bg);
		position: sticky;
		gap: 8px;
		top: calc(var(--global-header-height) + var(--schedule-header-height));
		--weekdays-font-size: 16px;
		font-size: var(--weekdays-font-size);
		padding-inline: var(--main-x-padding);

		.date {
			display: flex;
			flex-wrap: wrap;
			padding-block: 10px;
			justify-content: center;
			align-items: center;
			gap: 0.3em;
			font-weight: 500;
			border: 1.5px solid transparent;
			transition: opacity 150ms ease-in;
			filter: opacity(0.3);
			&.present {
				filter: opacity(1);
			}
			color: var(--white-to-ocean);
			&.today {
				padding-block: 6px;

				.schedule-week__day {
					transform: scale(1.3);
					background-color: var(--graphite-to-soft-ocean);

					padding: 4px 12px;
					border-radius: 8px;
				}
			}
			> span {
				letter-spacing: 0.02rem;
			}
		}
		@include xxl {
			--weekdays-font-size: 14px;
		}
		@include xl {
			--weekdays-font-size: 12px;
		}
	}
	&__weekdays-mob {
		display: grid;
		position: sticky;
		top: calc(var(--global-header-height) + var(--schedule-header-height));
		grid-template-columns: repeat(7, minmax(40px, 1fr));
		padding: 10px var(--main-x-padding);
		z-index: 6;
		background-color: var(--main-bg);
		gap: 10px;
		text-align: center;
		margin-top: 24px;
		.date {
			transition: transform 0.3s ease-in-out;
			font-weight: 500;
			font-size: 14px;
			pointer-events: none;
			opacity: 0.5;
			&.has-classes {
				opacity: 1;
				pointer-events: all;
			}
			&:hover {
				cursor: pointer;
				transform: scale(1.1);
			}

			&.choosen {
				transform: scale(1.2);

				.day {
					font-size: 20px;
				}
			}
			.day {
				background-color: var(--graphite-to-soft-ocean);
				height: 40px;
				display: grid;
				place-content: center;
				margin-bottom: 4px;
				color: var(--white-to-ocean);
				// transition: , transform;
				// transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
			}
		}
	}

	&__classes {
		margin-top: 8px;
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(7, minmax(130px, 1fr));
		width: 100%;
		justify-content: center;
		padding-block: 8px;
		overflow-y: auto;
		padding-inline: var(--main-x-padding);
		&__card {
			display: flex;
			flex-direction: column;
			gap: 8px;
			transition: all 0.5s ease-in-out;
			@include lg {
				align-items: center;
			}
		}
		&__empty {
			--empty-card-logo-height: 75px;
			--empty-card-text-height: 100px;
			--empty-card-padding: 12px;
			height: calc(
				var(--empty-card-logo-height) + var(--empty-card-text-height) +
					var(--empty-card-padding) * 2
			);
			max-width: 340px;
			border: 1px solid var(--white-soft);
			color: var(--white-soft-to-ocean);
			border-radius: 6px;
			display: grid;
			place-content: center;
			background-color: var(--main-bg);
			gap: 8px;
			padding: var(--empty-card-padding);
			> span {
				height: var(--empty-card-text-height);
			}
			> img {
				height: var(--empty-card-logo-height);
			}
			font-size: 1.125rem;
			text-align: center;
			&__logo {
				color: var(--white-soft-to-ocean);
				width: 100%;
			}
		}
		@include lg {
			grid-template-columns: 1fr;
			grid-template-rows: auto;
			gap: 0;
		}
	}

	@include lg {
		max-width: 400px;
		align-self: center;
	}
}
