.change-password-form {
  width: 100%;
  max-width: 600px;
  font-family: 'Open sans';
  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 14px;
    font-weight: 600;
    &__control {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &__label {
        display: flex;
        gap: 10px;
      }
    }
    &__btn {
      margin: 15px auto;
    }
  }
  &-success {
    max-width: 400px;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      &__title {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
