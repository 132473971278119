// .player-card {
// 	display: flex;
// 	flex-direction: column;
// 	gap: 8px;
// 	align-items: center;
// 	border: 1px solid var(--white-soft);
// 	background-color: var(--graphite);
// 	box-shadow: 0 12px 32px var(--card-shadow);
// 	border-radius: 8px;
// 	padding: 8px;
// 	&.current-player {
// 		border-color: var(--light-green);
// 		background-color: var(--deep-black);
// 	}
// 	&__avatar {
// 		height: 80px;
// 		width: 80px;
// 		aspect-ratio: 1;
// 		border-radius: 50%;
// 		object-fit: cover;
// 	}
// 	&__info {
// 		display: flex;
// 		flex-direction: column;
// 		min-width: 0;
// 		> span {
// 			max-width: 100px;
// 			text-align: center;
// 		}
// 	}
// }
.player-card {
	display: flex;
	gap: 10px;
	background-color: var(--white-soft-hard-to-graphite-soft);
	padding: 5px 10px;
	border-radius: 8px;
	align-items: center;
	border: 1px solid transparent;
	&.current-player {
		background-color: var(--deep-black);
		border-color: var(--white-soft);
	}
	&__avatar {
		height: 40px;
		width: 40px;
		aspect-ratio: 1;
		border-radius: 50%;
		object-fit: cover;
	}
}
