.layout {
	position: relative;
	background: var(--main-bg);

	@supports (min-height: 100dvh) {
		min-height: 100dvh;
	}
	min-height: 100vh;
}

.main {
	position: relative;
	@supports (min-height: 100dvh) {
		min-height: calc(
			100dvh - var(--global-header-height) - var(--global-footer-height)
		);
	}
	min-height: calc(
		100vh - var(--global-header-height) - var(--global-footer-height)
	);
}
