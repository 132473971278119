@import 'src/app/styles/breakpoints';
.footer {
	color: var(--white);
	width: 100%;
	overflow: hidden;
	height: var(--global-footer-height);
	z-index: 1;
	background-color: var(--footer-bg);
	background-color: var(--main-bg);
	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		padding: 0 var(--main-x-padding);
		z-index: 2;

		@include md {
			flex-direction: column;
			justify-content: center;
			gap: 30px;
		}
		&__social {
			display: flex;
			gap: 1.5rem;
			height: 24px;
			align-items: center;
			&__icon {
				width: 24px;
				height: 24px;
				color: var(--white);
			}
		}
		&__policy {
			text-transform: uppercase;
			transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
			color: var(--white-soft-to-ocean);
			&:hover {
				color: var(--white-to-ocean);
			}
		}
	}
}
