.workout-info {
	display: flex;
	flex-direction: column;
	gap: 40px;
	&__main {
		display: flex;
		flex-direction: column;
		gap: 20px;
		&__description {
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 14px;
			font-weight: 700;
		}
		&__progress {
			display: flex;
			flex-direction: column;
			gap: 10px;
			&__text {
				font-size: 24px;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: 0.5px;
			}

			&__free-leave-tip {
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
	&__additional {
		display: flex;
		flex-direction: column;
		gap: 20px;
		font-size: 14px;
		> h3 {
			font-size: 24px;
			font-weight: 700;
			line-height: 32px;
		}
	}
	&__buttons {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 5px;
		font-size: 14px;
		@media screen and (max-width: 768px) {
			gap: 10px;
			> button {
				max-width: 100%;
			}
		}
	}
}
