.toggle-checkbox {
	background-color: var(--graphite);
	border: 1px solid var(--white-soft);
	border-radius: 4px;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 16px;
	position: relative;
	width: 100%;
	min-width: 0;
	transition: all 0.3s ease-in-out;
	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	&__content {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}
	&.active {
		background-color: var(--deep-black);
	}
	&__avatar {
		width: 1.5em;
		height: 1.5em;
		border-radius: 50%;
		object-fit: cover;
	}
	&__icon {
		width: 1.25em;
		height: 1.25em;
		transition: color 0.3s ease-in-out;
		&.active {
			color: var(--accentColor);
		}
		&--check {
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
			color: var(--primary-green);
			&.checked {
				opacity: 1;
			}
		}
	}
	&:hover {
		&:not(.active) {
			cursor: pointer;
			opacity: 0.7;
		}
	}
}
