@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: 'Docker';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./docker-one.woff') format('woff');
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('roboto-regular-russian.woff2') format('woff2'),
		url('roboto-regular-russian.woff') format('woff'),
		url('roboto-regular-russian.ttf') format('truetype');
	unicode-range: U+21-23, U+25-5F, U+61-7E, U+AB, U+BB, U+401, U+410-44F, U+451,
		U+2013-2014, U+2018-2019, U+201C, U+201C-201E, U+2026, U+20BD;
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('roboto-bold-russian.woff2') format('woff2'),
		url('roboto-bold-russian.woff') format('woff'),
		url('roboto-bold-russian.ttf') format('truetype');
	unicode-range: U+21-23, U+25-5F, U+61-7E, U+AB, U+BB, U+401, U+410-44F, U+451,
		U+2013-2014, U+2018-2019, U+201C, U+201C-201E, U+2026, U+20BD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./OpenSans-Light.ttf') format('ttf');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./open-sans.woff') format('woff');
}
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('./open-sans-semibold.woff') format('woff');
}
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./open-sans-semibold.woff') format('woff');
}
@font-face {
	font-family: 'Bebas';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./bebas-neue-bold.woff') format('woff');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./Inter-Regular.woff') format('woff');
}
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 400;
	src: url('ubuntu-regular-russian.woff2') format('woff2'),
		url('ubuntu-regular-russian.woff') format('woff'),
		url('ubuntu-regular-russian.ttf') format('truetype');
	unicode-range: U+21-23, U+25-5F, U+61-7E, U+AB, U+BB, U+401, U+410-44F, U+451,
		U+2013-2014, U+2018-2019, U+201C, U+201C-201E, U+2026, U+20BD;
}
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 300;
	src: url('ubuntu-light-russian.woff2') format('woff2'),
		url('ubuntu-light-russian.woff') format('woff'),
		url('ubuntu-light-russian.ttf') format('truetype');
	unicode-range: U+21-23, U+25-5F, U+61-7E, U+AB, U+BB, U+401, U+410-44F, U+451,
		U+2013-2014, U+2018-2019, U+201C, U+201C-201E, U+2026, U+20BD;
}
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 500;
	src: url('ubuntu-medium-russian.woff2') format('woff2'),
		url('ubuntu-medium-russian.woff') format('woff'),
		url('ubuntu-medium-russian.ttf') format('truetype');
	unicode-range: U+21-23, U+25-5F, U+61-7E, U+AB, U+BB, U+401, U+410-44F, U+451,
		U+2013-2014, U+2018-2019, U+201C, U+201C-201E, U+2026, U+20BD;
}
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 700;
	src: url('ubuntu-bold-russian.woff2') format('woff2'),
		url('ubuntu-bold-russian.woff') format('woff'),
		url('ubuntu-bold-russian.ttf') format('truetype');
	unicode-range: U+21-23, U+25-5F, U+61-7E, U+AB, U+BB, U+401, U+410-44F, U+451,
		U+2013-2014, U+2018-2019, U+201C, U+201C-201E, U+2026, U+20BD;
}
