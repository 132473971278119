.tab {
	border-bottom: 2px solid transparent;

	padding-block: 8px;
	font-size: 1.125rem;
	font-weight: 700;
	white-space: nowrap;
	transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	color: var(--white-soft);
	@media (hover: hover) {
		&:hover {
			transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
			// border-color: var(--primary-green-soft);
			cursor: pointer;
			color: var(--white);
		}
	}

	&.active {
		border-color: var(--light-green);
		color: var(--white);
	}
}
