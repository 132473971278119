@import 'src/app/styles/breakpoints';
.product-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 60px;
	flex-shrink: 0;
	width: 375px;
	font-weight: 400;
	color: var(--white);
	border-radius: 10px;
	padding: 24px;
	letter-spacing: 0.03em;
	background-color: var(--graphite-to-gray);
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-weight: 700;
		font-size: 1.25rem;
		&__product-name {
			color: var(--light-green);
			text-transform: uppercase;
		}
	}
	&__prices {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 50px;
		&__per-training {
			font-size: 14px;
			margin-bottom: 5px;
		}
		&__discount {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 1.725rem;
			&__price {
				text-decoration: line-through;
				opacity: 50%;
				font-size: 0.925rem;
				justify-self: baseline;
			}
		}
		&__price {
			font-weight: 700;
		}
	}
	&__description {
		display: flex;
		flex-direction: column;
		gap: 12px;
		font-size: 15px;
		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__total {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		border-top: 1px solid var(--light-green);
		align-items: center;
		font-weight: 700;
		font-size: 22px;
	}
	&__button {
		width: 100%;
		max-width: 100%;
	}
	@include lg {
		max-width: 400px;
		width: calc(100vw - 2 * var(--main-x-padding));
	}
}
