.profile-subscribe {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	--card-width: 340px;
	width: var(--card-width);
	@media screen and (max-width: 1920px) {
		font-size: 14px;
		--card-width: 280px;
	}
	@media screen and (max-width: 450px) {
		--card-width: 100%;
		flex: 1 0 100%;
	}
	&__wrapper {
		position: relative;
		border: 2px solid transparent;
		transition: all 0.3s ease-in-out;
		padding-bottom: 60px;
		height: 500px;
		@media screen and (max-width: 1920px) {
			border-width: 1px;
			padding-bottom: 20px;
			height: 430px;
		}
		@media screen and (max-width: 450px) {
			height: 550px;
			font-size: 20px;
			.profile-subscribe__icon {
				height: 150px;
				width: 150px;
			}
		}
		&.active {
			border-color: var(--green);
		}
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		&__type {
			font-size: 0.85em;
			> span {
				max-width: 90px;
			}
		}
	}
	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__tip {
		font-size: 14px;
		opacity: 0.6;
		text-align: center;
		max-width: 70%;
		@media screen and (max-width: 1920px) {
			font-size: 12px;
		}
	}
	&__icon {
		width: 100px;
		height: 100px;
		margin: auto;
		&.active {
			circle {
				fill: var(--green);
			}
		}
	}
	&__freeze {
		background-color: #40404099;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 20px;
	}
	&__actions {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		border-radius: 20px;
		width: 100%;
		height: 100%;
		transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 10px;
		background-color: #00000099;
		&__unfreeze {
			height: 36px;
			@media screen and (max-width: 450px) {
				height: 42px;
			}
			&.animation {
				animation: success-button 1s ease-in-out;
				pointer-events: none;
				cursor: default;
				.profile-subscribe__actions__unfreeze__content {
					animation: success-mark 1.5s ease-in-out;
					border-radius: 2px;
					border-bottom: 3px solid var(--green);
					border-right: 3px solid var(--green);
					transform: rotate(45deg);
					width: 10px;
					height: 15px;
				}
			}
		}
	}
	&:not(:has(.freeze)):hover {
		.profile-subscribe__actions {
			visibility: visible;
			opacity: 1;
		}
	}
}
@keyframes success-button {
	0% {
		width: 0;
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
	100% {
		width: 100%;
	}
}
@keyframes success-mark {
	0% {
		width: 0;
		height: 0;
		border-width: 0;
	}
	25% {
		width: 0;
		height: 0;
		border-width: 0;
	}
	50% {
		border-width: 3px;
		width: 10px;
	}
	60% {
		height: 15px;
	}
}
