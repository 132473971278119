.workout-sign-up {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #111111;
	max-height: 500px;
	min-height: 170px;
	max-width: 800px;
	border-radius: 20px;
	z-index: 27;
	padding: 10px 5px;
	&__header {
		padding: 10px 20px;
		&__title {
			font-size: 24px;
			font-weight: 700;
		}
	}
	&__wrapper {
		position: relative;
		height: 100%;
		padding: 0 20px;
		padding-bottom: 20px;
		overflow-y: auto;
	}
	&__squad {
		&__title {
			font-size: 24px;
			font-weight: 700;
		}
		&__list {
			padding: 5px 0;
			display: flex;
			overflow: auto;
			--columns: 1;

			gap: 5px;
			z-index: 1;
			display: grid;
			grid-template-columns: repeat(var(--columns), 1fr);
			&__item {
				background-color: #292a2d;
				font-size: 12px;
				font-weight: 600;
				flex-grow: 1;
				border: 1px solid #ffffff80;
				border-radius: 5px;
				padding: 10px;
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	&__footer {
		background: inherit;
		border-radius: 0 0 20px 20px;
		position: sticky;
		width: 100%;
		padding: 20px;
		top: 0;
		&__free-leave-tip {
			font-size: 14px;
			opacity: 0.7;
		}
	}
}
@keyframes slide-in {
	from {
		max-height: 0;
	}
	to {
		max-height: 1000px;
	}
}

@keyframes slide-out {
	from {
		height: var(--popup-height);
	}
	to {
		height: 0;
	}
}
