@import 'src/app/styles/breakpoints';
.filter-manage {
	display: flex;
	gap: 20px;
	&__trigger-btn {
		padding: 10px;
		width: 40px;
		> svg {
			transition: fill 150ms ease-in, stroke 150ms ease-in;
			stroke: var(--white-to-ocean);
		}
		&.active {
			background-color: var(--primary-green);
			> svg {
				stroke: white;
				fill: white;
			}
		}
		&:hover {
			border-color: transparent;
			background-color: var(--primary-green-soft);
			> svg {
				stroke: white;
			}
		}
	}
	&__btn {
		padding-inline: 30px;
		width: fit-content;
		position: relative;
	}
	@include lg {
		gap: 10px;
		font-size: 0.9em;
		&__btn {
			padding-inline: 20px;
		}
	}
	@include md {
		display: none;
	}
}
