.big-progress-bar {
	display: flex;
	height: 25px;
	@media screen and (max-width: 768px) {
		height: 16px;
	}
	gap: 4px;
	&__item {
		transition: all 0.3s ease-in-out;
		border: 1px solid #fff;
		border-radius: 5px;
		flex-grow: 1;
		&.active {
			background-color: var(--yellow);
			border-color: var(--yellow);
		}
	}
}
