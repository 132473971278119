.close-button {
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 10;
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: grid;
	place-content: center;
	background-color: var(--graphite);
	height: 32px;
	width: 32px;
	border-radius: 8px;
	padding: 8px;
	transition: all 150ms linear;
	color: var(--gray);
	&:hover {
		transform: scale(1.1);
		> svg {
			color: var(--white);
		}
	}
	> svg {
		transition: all 150ms linear;
		color: inherit;
	}
}
