.workout-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	padding: 10px 0;
	margin-bottom: 10px;
	position: sticky;
	top: var(--global-header-height);
	z-index: 50;
	background-color: var(--graphite);
	font-size: 24px;
	&__left-side {
		display: flex;
		gap: 30px;
	}
	&__back {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		transition: text-shadow 0.3s ease-in-out;
		@media screen and (max-width: 768px) {
			font-size: 20px;
		}
		&__icon {
			transition: transform 0.3s ease-in-out;
			@media screen and (max-width: 768px) {
				width: 40px;
				height: 40px;
			}
		}
		&:hover {
			.workout-header__back__icon {
				transform: scale(1.1);
			}
			text-shadow: var(--green) 0 0 4px;
		}
	}

	&__date {
		display: flex;
		gap: 40px;
		font-weight: 700;
		align-items: center;
		&__interval {
			font-weight: 700;
			> svg {
				height: 20px;
				aspect-ratio: 1 / 1;
				width: 20px;
			}
		}
		&__data {
			> svg {
				height: 20px;
				aspect-ratio: 1 / 1;
				width: 20px;
			}
		}
	}
	&__actions {
		display: flex;
		gap: 10px;
		@media screen and (max-width: 768px) {
			&__icon {
				width: 40px;
				height: 40px;
			}
		}

		&__share {
			position: relative;
			display: inline-block;

			&__tooltip {
				visibility: hidden;
				opacity: 0;
				background-color: #000;
				color: #fff;
				text-align: center;
				border-radius: 5px;
				text-wrap: nowrap;
				padding: 10px;
				font-size: 16px;
				@media screen and (max-width: 768px) {
					font-size: 12px;
				}
				position: absolute;
				z-index: 28;
				top: 110%;
				left: -130%;
				transition: opacity 0.3s;
				&::after {
					content: '';
					position: absolute;
					top: -25%;
					left: 50%;
					margin-left: -5px;
					transform: rotate(180deg);
					border-width: 5px;
					border-style: solid;
					border-color: #000 transparent transparent transparent;
				}
				&__success {
					display: flex;
					align-items: center;
					gap: 10px;
					font-size: 0.85em;
					text-wrap: nowrap;
					&__icon {
						width: 15px;
						height: 15px;
					}
				}
			}

			&:hover > &__tooltip {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
