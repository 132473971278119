.loader-filter {
	display: flex;
	flex-direction: column;
	gap: 24px;

	&__group {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	&__item {
		border-radius: 8px;
	}
}
