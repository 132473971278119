.interval {
	display: flex;
	gap: 0.5rem;
	font-weight: 500;
	align-items: center;
	line-height: 1em;
	> svg {
		width: 24px;
		height: 24px;
	}
}
