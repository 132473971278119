@import "./src/assets/styles/variables";

.map > div:first-child {
  width: 100% !important;
  height: 390px !important;

  @media (min-width: $md) {
    height: 650px !important;
  }
}

// Custom balloon
.ymaps-2-1-79-copyrights-pane {
  @media (max-width: $max-md) {
    inset: auto 0 0 !important;
    z-index: 5002 !important;
  }
}

.ymaps-2-1-79-balloon {
  padding: 0 !important;
  box-shadow: none !important;

  &__layout {
    background-color: $graphite !important;
    border-radius: 10px;

    @media (min-width: $md) {
      clip-path: polygon(30px 0, calc(100% - 18px) 0, calc(100% - 18px) 142px, 100% 164px, 100% calc(100% - 30px), calc(100% - 70px) calc(100% - 30px), calc(100% - 90px) 100%, 0 100%, 0 30px) !important;
    }
  }

  &_layout_panel {
    top: -170px;
    bottom: auto !important;
    margin: 0 auto;
    width: calc(100% - 30px);

    .ymaps-2-1-79-balloon__content {
      min-height: 0;
    }
  }

  &__close {
    position: relative;
    top: 10px;
    right: 10px;

    @media (min-width: $md) {
      top: 18px;
      right: 38px;
    }

    &-button {
      position: relative;
      width: 25px !important;
      height: 25px !important;
      background: $yellow !important;
      opacity: 1 !important;
      border-radius: 50%;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 3px;
        background-color: $graphite;
        top: 50%;
        left: 50%;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__content {
    margin: 0 !important;
    padding: 0 !important;
    background-color: $graphite !important;

    & > ymaps {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__tail {
    box-shadow: none !important;

    &:after {
      bottom: 0 !important;
      left: 0 !important;
      background-color: $graphite !important;
    }
  }
}

#balloon-wrapper {
  @media (min-width: $md) {
    width: 385px;
    min-height: 200px;
  }
}
