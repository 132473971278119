@import "../../assets/styles/variables";

// Gym preview wrapper with decor
.gym-types .tcp-wrapper {
  @media (min-width: $md) {
    padding-bottom: 0;
    padding-left: 0;
  }

  @media (min-width: $lg) {
    padding-top: 30px;
    padding-right: 30px;
  }

  @media (min-width: $xl) {
    padding-bottom: 30px;
    padding-left: 30px;
  }

  &__top-decor {
    @media (max-width: $max-md) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform: scale(-1, -1);
    }
  }

  &__bottom-decor {
    @media (max-width: $max-md) {
      top: -28px;
      right: 28px;
      bottom: auto;
      left: auto;
      transform: rotate(90deg) scale(1, -1);
    }

    @media (min-width: $md) {
      display: none;
    }
  }
}

// Gym preview
.gym-types .tcp {
  @media (max-width: $max-md) {
    clip-path: polygon(100% 0, 100% 100%, 65px 100%, 0 calc(100% - 65px), 0 0);
  }

  &:before {
    @media (max-width: $max-md) {
      bottom: 30px;
      left: -15px;
      top: auto;
    }
  }

  &__image {
    @media (max-width: $max-md) {
      clip-path: polygon(100% 0, 100% 100%, 55px 100%, 0 calc(100% - 55px), 0 0);
    }
  }

  &__content-button {
    @media (max-width: $max-xs) {
      max-width: 150px;
    }

    @media (min-width: $sm) and (max-width: $max-md) {
      max-width: 150px;
    }
  }
}

// Gym grid style
.page-title--gym {
  margin-bottom: 15px;

  @media (min-width: $lg) {
    margin-bottom: 30px;
  }
}

.gym {
  margin: 60px 0;

  @media (min-width: $md) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    grid-template-rows: auto 65px auto;
    gap: 30px;
    margin-top: 120px;
    margin-bottom: 100px;
    grid-template-areas:
        "gym-images gym-title"
        "gym-images gym-schedule"
        "gym-info gym-info"
  }

  @media (min-width: $lg) {
    grid-template-rows: 25px auto 65px;
    grid-template-areas:
        "gym-images gym-title"
        "gym-images gym-info"
        "gym-images gym-schedule"
  }

  &__images {
    @media (max-width: $max-md) {
      display: none;
    }

    @media (min-width: $md) {
      grid-area: gym-images;
    }
  }

  &__title {
    @media (min-width: $md) {
      grid-area: gym-title;
    }
  }

  &__info {
    @media (min-width: $md) {
      grid-area: gym-info;
    }
  }

  &__schedule {
    @media (min-width: $md) {
      grid-area: gym-schedule;
    }
  }

  &__images,
  &__title,
  &__info {
    @media (max-width: $max-md) {
      margin-bottom: 30px;
    }
  }

  &__info-navigation {
    margin-bottom: 30px;
  }
}

// Gym items style
.gym {
  &__rating {
    pointer-events: none;
  }

  &__schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-title;
  }

  .tab-navigations p {
    @media (min-width: $lg) {
      cursor: pointer;
      transition: color .2s ease;
    }

    @media (any-hover: hover) {
      &:hover {
        color: $yellow;
      }
    }
  }
}

// Gym video
.gym-video {
  margin: 100px 0;

  &__wrapper {
    position: relative;
    padding-top: 280px;
    clip-path: polygon(30px 0, calc(100% - 30px) 0, 100% 30px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 30px 100%, 0 calc(100% - 30px), 0 30px);

    @media (min-width: $lg) {
      padding-top: 460px;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// Gym reviews section style
.gym-reviews {
  margin: 100px 0;
}
