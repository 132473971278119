.rating-container {
	display: flex;
	&__item {
		width: 35px;
		height: 35px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		&.readonly {
			pointer-events: none;
			cursor: default;
		}
		&.filled {
			> svg {
				stroke: var(--black);
				fill: var(--yellow);
			}
		}
	}
}
