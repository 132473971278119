.profile-subscribes {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 30px;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		&__title {
			display: flex;
			align-items: center;
			gap: 10px;
			&__text {
				font-size: 30px;
				font-weight: 500;
			}
		}
		&__button {
			font-size: 14px;
			font-weight: 700;
			width: 170px;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
	}
	&__content {
		display: flex;
		gap: 20px;
		overflow: auto;
		padding-bottom: 40px;
	}
	&__footer {
		position: fixed;
		bottom: 0;
		background-color: #111111;
		z-index: 11;
		width: 100%;
		margin-left: calc(0px - var(--main-x-padding));
		padding: 20px var(--main-x-padding);
	}
}
