@import "../../assets/styles/variables";

.tab-navigations {
  .swiper-wrapper {
    @media (min-width: $md) {
      display: flex;
      justify-content: space-between;
      max-width: none;
    }
  }

  &,
  & .swiper-slide {
    height: auto;
  }

  & .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;

    @media (min-width: $md) {
      width: auto!important;
    }
  }
  
  & .swiper-button-next,
  & .swiper-button-prev {
    color: $white;
    background-color: $graphite;
    transition: opacity 0ms;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-next {
    right: 0;
  }

  & p {
    font-size: 20px;

    @media (max-width: $max-md) {
      font-family: $font-title;
    }

    @media (min-width: $md) {
      position: relative;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
    }

    &:after {
      @media (min-width: $md) {
        content: '';
        transform: translateX(-50%);
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        background: $green;
        transition: .2s ease;
      }
    }

    @media (any-hover: hover) {
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }

    &.tab-navigation--active {
      color: $yellow;

      &:after {
        width: 100%;
      }
    }
  }
}
