@import 'src/app/styles/breakpoints';
.sign-up {
	display: flex;
	flex-direction: column;
	gap: 24px;

	&__title {
		font-size: 32px;
		font-weight: 700;
		text-align: center;
	}
	&__form {
		display: flex;
		flex-direction: column;
		gap: 16px;
		> button {
			margin-inline: auto;
			max-width: 192px;
		}
	}
	&__policy-link {
		border-bottom: 1.5px solid var(--white);
	}
	&__form-control {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 5px;
		height: 45px;
		@include sm {
			> label {
				font-size: 11px;
			}
		}
	}
	&__error {
		display: flex;
		gap: 4px;
		align-items: center;
		font-size: 12px;
		@include sm {
			font-size: 11px;
		}
		> svg {
			color: var(--red);
		}
	}
	&__confirm {
		display: flex;
		justify-content: center;
		gap: 16px;
	}
	&__description {
		color: var(--white-soft);
		font-size: 14px;
		font-weight: 600;
		text-align: center;
	}
}
