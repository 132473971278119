@import "../../../assets/styles/variables";

.rules-block {
  padding-top: 10px;

  &__title {
    font-weight: 400;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    padding-top: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 45px;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    color: $grey;
    max-height: 0;
    overflow: hidden;
    transition: .2s ease-out;

    p {
      color: $grey;
    }
  }

  &__list li {
    padding-left: 10px;
  }

  &__list li::marker {
    color: $grey;
    content: "• ";
    font-size: 20px;
  }

  &__content {
    cursor: pointer;
    border-bottom: 1px solid rgba(114, 191, 68, 0.5);

    &.open {
      .rules-block__list {
        transition: .2s ease-in-out;
        max-height: 1000px;
        padding-bottom: 20px;
      }
    }
  }

  &__header {
    display: flex;
    gap: 30px;
    padding: 20px 0 20px 0;
  }

  &__subtitle {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
  }
}

@media #{$tablet} {
  .rules-block {
    padding-top: 20px;
    min-height: 100%;

    &__title {
      font-size: 18px;
      line-height: 18px;
    }

    &__container {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__header {
      gap: 45px;
    }
  }
}

@media #{$mobile} {
  .rules-block {
    &__title {
      padding-top: 36px;
      padding-bottom: 34px;
      font-size: 20px;
      line-height: 20px;
    }

    &__container {
      padding-top: 0;
    }
  }
}