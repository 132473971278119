.popup-account {
	color: var(--white);
	&__title {
		font-size: 32px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 10px;
		margin-top: -10px;
		text-transform: capitalize;
	}
	&__form {
		color: #fff;
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__control {
			font-size: 14px;
			&__wrapper {
				position: relative;
			}
			&__label {
				position: absolute;
				top: 30%;
				left: 15px;
				display: flex;
				gap: 10px;
				cursor: text;
				> span {
					color: var(--green);
				}
			}
			&__eye {
				position: absolute;
				top: 25%;
				right: 15px;
			}
			&__field {
				z-index: 2;
				width: 100%;
				background-color: var(--graphite);
				padding: 10px 15px;
				border-radius: 5px;
				border: 1px solid #ffffff80;
				&[aria-invalid='true'] {
					border-color: crimson;
				}
				&:focus {
					background-color: #18191b;
					~ .popup-account__form__control__eye {
						background-color: #18191b;
					}
				}
				&::placeholder {
					opacity: 0;
				}
				&:focus ~ label,
				&:not(:placeholder-shown) ~ label {
					opacity: 0;
				}
			}
			&__error {
				color: crimson;
				font-size: 12px;
				margin-top: 5px;
			}
		}
		&__forgot-password {
			margin-bottom: 10px;
			margin-left: 5px;
			width: fit-content;
		}
		&__btn {
			max-width: 192px;
			margin-top: 20px;
		}
	}
	// &__logout {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// 	gap: 20px;
	// }
}
