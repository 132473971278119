@import 'src/app/styles/breakpoints';
.workout-detail {
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-width: 100%;
	transition: filter 0.2s ease-in-out;
	filter: blur(5px);
	overflow-y: auto;
	&.active {
		filter: blur(0);
	}
	&__trainer {
		display: flex;
		// align-items: center;
		gap: 12px;
		&__avatar {
			height: 80px;
			aspect-ratio: 1;
			border-radius: 8px;
			object-fit: cover;
		}
		&__info {
			display: flex;
			flex-direction: column;
			gap: 2px;
			> span {
				&:not(:nth-child(1)) {
					font-size: 14px;
					color: var(--white-soft);
					@include sm {
						font-size: 12px;
					}
				}
			}
		}
	}
	&__group {
		display: flex;
		flex-direction: column;
		gap: 16px;
		&__title {
			color: var(--light-green);
			font-weight: 700;
			font-size: 14px;
			margin-bottom: -6px;
		}
	}
	&__video {
		border-radius: 4px;
	}
}
