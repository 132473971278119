.progress {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	width: 100%;
	font-size: 14px;
	&__level {
		display: flex;
		align-items: center;
		gap: 6px;
		justify-content: center;
		flex-direction: column;
		border-radius: 20px;
		background-color: #252525;
		width: 120px;
		aspect-ratio: 1 / 1;
		&__value {
			font-size: 20px;
			font-weight: 500;
			&_current {
				font-size: 30px;
			}
		}
		&__bar {
			display: flex;
			gap: 2px;
			&__item {
				height: 6px;
				width: 7px;
				border: 1px solid var(--white);
				&.filled {
					background-color: var(--green);
					border-color: var(--green);
				}
			}
		}
	}
}
