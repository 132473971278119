.profile-history-item {
	display: grid;
	grid-template-columns:
		minmax(var(--item-width), auto) minmax(var(--item-width), auto)
		auto auto;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding-bottom: 20px;
	background-image: linear-gradient(
		to right,
		var(--green) 50%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: bottom;
	background-size: 15px 1px;
	background-repeat: repeat-x;
	--item-width: 15vw;
	@media screen and (max-width: 1024x) {
		--item-width: 20vw;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: auto auto;
	}
	&__info {
		display: grid;
		grid-template-columns:
			minmax(var(--item-width), auto) minmax(var(--item-width), auto)
			auto;
		justify-content: space-between;
		align-items: center;
		width: 70%;
		gap: 10px;
		@media screen and (max-width: 768px) {
			width: 100%;
			justify-content: space-between;
			grid-template-columns: auto auto;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		gap: 5px;
		color: var(--green);
		@media screen and (max-width: 768px) {
			height: 2rem;
			order: 2;
		}
	}
	&__metro {
		max-width: var(--item-width);
		@media screen and (max-width: 768px) {
			order: 1;
			min-width: 150px;
		}
		> span:last-child {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__rate {
		color: var(--green);
		display: flex;
		gap: 5px;
		align-items: center;
		cursor: pointer;
		@media screen and (max-width: 768px) {
			grid-area: 'link';
			order: 2;
		}
		&__star {
			width: 35px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			> svg {
				stroke: var(--green);
			}
		}
	}
}
