.carousel {
	height: 100%;
	width: 100%;
	position: relative;
	&:hover {
		@media (hover: hover) {
			.carousel__control {
				opacity: 1;
				pointer-events: all;
			}
		}
	}
	&__control {
		z-index: 10;
		position: absolute;
		top: 50%;
		opacity: 0;
		pointer-events: none;
		transform: translateY(-50%);
		right: 0;
		cursor: pointer;
		transition: opacity 150ms ease-out;
		> svg {
			transition: color 150ms ease-in, opacity 150ms ease-out;
		}
		&:disabled {
			pointer-events: none;
			> svg {
				opacity: 0;
			}
		}
		&:hover {
			@media (hover: hover) {
				> svg {
					color: #a1a1a1;
				}
			}
		}
		&.left {
			right: unset;
			left: 0;
			> svg {
				transform: rotate(180deg);
			}
		}
	}
	&__scrollable {
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		width: 100%;
		scroll-behavior: smooth;
	}
	&__content {
		display: flex;
		height: 100%;
	}
	&__item {
		flex-shrink: 0;
		> img {
			height: 100%;
			aspect-ratio: 1 / 1;
			object-fit: cover;
			border-radius: 4px;
		}
	}
}
