.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	transition: all 150ms ease-in;
	line-height: 1;
	min-width: 0;
	white-space: nowrap;
	border-radius: 6px;
	font-weight: 500;
	position: relative;
	max-width: 192px;
	width: 100%;
	height: 40px;
	gap: 16px;
	&__full-width {
		max-width: 100%;
	}
	&__icon {
		width: 1em;
		height: 0.875em;
		position: relative;
		top: 1px;
		color: var(--white);
	}
	&:hover {
		cursor: pointer;
	}
	&_theme_black {
		color: var(--white);
		border: 1px solid #444444;
		background: linear-gradient(
			207.26deg,
			#222222 4.71%,
			#111111 91.04%,
			#292a2d 100%
		);
		&:hover {
			opacity: 0.8;
		}
	}
	&_theme_green {
		color: #fff;
		border: 1px solid transparent;
		background-color: var(--primary-green);

		&:hover {
			opacity: 0.9;
		}
	}
	&_theme_yellow,
	&_theme_yellow-soft {
		color: var(--black);
		border: 1px solid transparent;
		background-color: var(--yellow);
		&-soft {
			background-color: var(--yellow-soft);
		}
		.button__loader {
			border-top-color: var(--graphite);
		}
		&:hover {
			opacity: 0.8;
		}
	}

	&_theme_transparent-green {
		color: var(--white);
		border: 1px solid var(--primary-green);
		background-color: transparent;

		&:hover {
			color: var(--white);
			border-color: var(--dark-green);
			background-color: var(--dark-green);
		}
	}
	&_theme_black-green {
		color: var(--white);
		border: 1px solid var(--primary-green);
		background-color: var(--black-to-gray);

		&:hover {
			color: #fff;
			background-color: var(--primary-green);
			> svg {
				color: #fff;
			}
		}
	}
	&_theme_red {
		color: var(--white);
		border: 1px solid var(--fog-red);
		background-color: var(--black-to-gray);

		&:hover {
			color: #fff;
			background-color: var(--fog-red);
			.button__icon {
				color: #fff;
			}
		}
	}
	&:disabled {
		opacity: 70%;
		z-index: 4;
		pointer-events: none;
	}
	&__loading {
		pointer-events: none;
		opacity: 0.7;
	}
	&__loader {
		width: 1em;
		height: 1em;
		border-radius: 50%;
		display: inline-block;
		border-right: 3px solid transparent;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
		border-top: 3px solid var(--white);
		@keyframes rotation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
	&__bold {
		font-weight: 700;
	}
	&__rounded {
		border-radius: 16px;
	}
	&__centered {
		margin: auto;
	}
	&__large {
		max-width: 340px;
	}
	&-link {
		width: fit-content;
		color: var(--white);
		transition: transform 250ms ease-in-out;
		&:hover {
			@media (any-hover: hover) {
				transform: scale(1.05);
			}
		}
		&:disabled {
			opacity: 70%;
			z-index: 4;
			pointer-events: none;
		}
	}
}
