@import '../../assets/styles/variables.scss';

// Articles list
.articles-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}

// Pagination
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 60px;

  .previous,
  .next {
    display: none;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover {
      background: $green;
      color: $white;
    }
  }

  .active a {
    background: $green;
  }
}
