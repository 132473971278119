@import 'variables';

// Borders
@mixin border($borderWidth: 1, $borderType: solid, $borderColor: $green) {
  @if ($borderWidth == 0) {
    border: 0;
  } @else {
    border: $borderWidth+px $borderType $borderColor;
  }
}

@mixin borderT ($borderWidth: 1, $borderType: solid, $borderColor: $green) {
  @if ($borderWidth == 0) {
    border-top: 0;
  } @else {
    border-top: $borderWidth+px $borderType $borderColor;
  }
}

@mixin borderR ($borderWidth: 1, $borderType: solid, $borderColor: $green) {
  @if ($borderWidth == 0) {
    border-right: 0;
  } @else {
    border-right: $borderWidth+px $borderType $borderColor;
  }
}

@mixin borderB ($borderWidth: 1, $borderType: solid, $borderColor: $green) {
  @if ($borderWidth == 0) {
    border-bottom: 0;
  } @else {
    border-bottom: $borderWidth+px $borderType $borderColor;
  }
}

@mixin borderL ($borderWidth: 1, $borderType: solid, $borderColor: $green) {
  @if ($borderWidth == 0) {
    border-left: 0;
  } @else {
    border-left: $borderWidth+px $borderType $borderColor;
  }
}
