.workout-detail-photo {
	display: flex;
	flex-direction: column;
	gap: 32px;
	min-width: 100%;
	transition: filter 0.2s ease-in-out;
	filter: blur(5px);
	overflow-y: auto;
	&.active {
		filter: blur(0);
	}
	#video-container {
		height: 100%;
		width: 100%;
		#video {
			border-radius: 4px;
			width: 100%;
			height: 100%;
		}
	}
}
