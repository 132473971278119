.app.dark {
	--dark-green: #1b5e20;
	--primary-green: #337b3a;
	--primary-green-soft: #337b3a99;
	--light-green: #72bf44;
	--fog-red: #d33838;
	--light-green-to-soft-ocean: #72bf44;
	--black: #222222;
	--blue: rgb(168 199 250 / 1);
	--graphite: #292a2d;
	--graphite-soft: #292a2d66;
	--white: #fff;
	--white-soft: #ffffff66;
	--deep-black: #18191b;
	--black-to-ocean: #18191b;
	--gray: #7e7e7e;
	--yellow: #faf62d;
	--yellow-soft: #fedc43;
	--orange: #fa662d;
	--white-soft-hard: #ffffff1a;
	--white-soft-medium: #ffffff33;
	--header-bg: #18191b;
	--footer-bg: inherit;
	--black-to-gray: #18191b;
	--main-bg: #18191b;
	--white-to-ocean: #fff;
	--green-to-orange: #337b3a;
	--deep-black-to-white: #18191b;
	--dark-black-to-white: #111111;
	--dark-black-to-gray: #18191b;
	--graphite-to-white: #292a2d;
	--dark-black-to-soft-ocean: #111111;
	--graphite-to-soft-ocean: #292a2d;
	--white-soft-to-ocean: #ffffff66;
	--gray-soft: #7e7e7e1a;
	--white-soft-hard-to-soft-ocean: #ffffff1a;
	--graphite-to-gray: #292a2d;
	--skeleton-main: #202020;
	--skeleton-main: #393d47;
	--white-soft-harder-to-soft-ocean: #ffffff0d;
	--tooltip-color: #ffffff0d;
	--skeleton-highlight: #ffffff1a;
	--overlay-bg: rgba(232, 232, 237, 0.48);
	--shadow: rgba(0, 0, 0, 0.65);
	--card-shadow: #292a2d;
	--white-soft-hard-to-graphite-soft: #ffffff1a;
	--red: #ff3037;
	--error-bg: #300;
}
