.slider-dots {
	display: flex;
	justify-content: center;
	z-index: 2;
	gap: 12px;
	padding: 20px 0;
	&.rectangle {
		gap: 2px;
		.slider-dots__dot {
			background-color: transparent;
			border-radius: 0px;
			border: 1px solid #ffffff;
			width: 18.5px;
			height: 8px;
			transition: all 0.3s ease-in-out;
			&.selected {
				background-color: var(--green);
				border-color: var(--green);
			}
		}
	}
	&__dot {
		background-color: #ffffff;
		border-radius: 5px;
		height: 10px;
		width: 10px;

		&:hover {
			cursor: pointer;
		}

		&.selected {
			background-color: var(--green);
		}
	}
}
