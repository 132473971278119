.profile-settings {
	margin-bottom: 60px;
	&__title {
		font-size: 30px;
		font-weight: 500;
		text-transform: uppercase;
	}
	&__form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__button {
			max-width: 220px;
		}
		@media screen and (max-width: 768px) {
			&__wrapper {
				position: fixed;
				bottom: 0;
				background-color: #111111;
				z-index: 11;
				width: 100%;
				margin-left: calc(0px - var(--main-x-padding));
				padding: 20px var(--main-x-padding);
			}
			&__button {
				max-width: 340px;
				margin: auto;
			}
		}
	}
	&__widgets {
		display: grid;
		--left-side-width: 300px;
		grid-template-columns: minmax(0, var(--left-side-width)) 1fr 1fr;
		gap: 20px;
		@media screen and (max-width: 1920px) {
			gap: 15px;
		}
		@media screen and (max-width: 1440px) {
			--left-side-width: 250px;
		}
		@media screen and (max-width: 1024px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				'secondary secondary'
				'primary contacts';
		}
		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'secondary'
				'primary'
				'contacts';
		}
	}
	&__modal {
		max-width: 400px;
		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 20px;
		}
	}
}
