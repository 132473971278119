.form-input {
	z-index: 2;
	width: 100%;
	background-color: var(--graphite);
	padding: 19px 16px 0;
	border-radius: 8px;
	border: 1px solid var(--white-soft);
	transition: transform 250ms ease, background-color 0.25s ease;
	transform: translate(0);
	height: 56px;
	&[aria-invalid='true'] {
		~ .label {
			color: crimson;
		}
		border-color: crimson;
	}
	&:focus {
		background-color: #18191b;
	}
	&::placeholder {
		opacity: 0;
		pointer-events: none;
	}
	&::-webkit-calendar-picker-indicator {
		filter: invert(1);
		display: none;
	}
	&:focus ~ .label,
	&:not(:placeholder-shown) ~ .label {
		transform: translate(-5px, -5px);
		color: var(--white);
	}
}
.pin-input {
	height: 60px;
	width: 45px;
	padding: 10px;
	border: 1px solid var(--white-soft);
	background-color: var(--graphite);
	border-radius: 8px;
	color: var(--white-to-ocean);
	font-weight: 700;
	font-size: 40px;
	text-align: center;
	&:focus {
		background-color: var(--deep-black);
	}
}
