@import '../../assets/styles/variables';

.main-banner {
	padding: 35.8% 0;

	@media (min-width: $sm) {
		padding: 22.2% 0;
	}

	@media (min-width: $lg) {
		padding: 20.84% 0;
	}

	&__content {
		&-wrapper {
			transform: translate(-50%, -50%);
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			text-align: center;
			background: linear-gradient(
				0deg,
				rgba(41, 42, 45, 0) 0%,
				rgba(114, 191, 68, 1) 100%
			);
			background: rgb(0 0 0 / 50%);
			background: linear-gradient(
				to bottom,
				#292a2d 0,
				hsl(225deg 5% 17% / 64%) 100%,
				transparent 105%
			);
		}

		&-action {
			margin-top: 30px;

			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				max-width: 245px;
				font-family: $font-title;
			}
		}
	}

	&__title {
		font-size: 20px;

		@media (min-width: $xs) {
			font-size: 24px;
		}

		@media (min-width: $md) {
			font-size: 28px;
		}

		@media (min-width: $lg) {
			font-size: 34px;
		}

		@media (min-width: $xl) {
			font-size: 45px;
		}
	}
}

.main-content {
	padding-top: 100px;

	&__item {
		&:not(:last-child) {
			margin-bottom: 120px;

			@media (min-width: $md) and (max-width: $max-lg) {
				margin-bottom: 70px;
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 120px;
	}
}

.map {
	position: relative;
	padding-bottom: 60px;
	&-section {
		margin-top: 120px;

		@media (min-width: $md) and (max-width: $max-lg) {
			margin-top: 70px;
		}
	}
}
