.slider-slide {
	flex: 1 0 100%;
	position: relative;
	height: calc(100% - 20px);

	&__img {
		border-radius: 20px;
		display: flex;
		margin: 0 auto;
		height: 100%;
		width: 100%;
		aspect-ratio: 16 / 9;
		object-fit: cover;
		max-height: 600px;
		@media screen and (min-height: 1860px) {
			max-height: 700px;
		}
	}

	&__title {
		text-align: center;
		margin-top: 10px;
	}
}
