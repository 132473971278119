@import "./../../../assets/styles/variables";

.balloon-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;

  @media (min-width: $md) {
    padding: 40px 58px 70px 40px;
    height: 100%;
  }

  &__title,
  &__address-block {
    margin-bottom: 24px;
  }

  &__title {
    display: none;

    @media (min-width: $md) {
      display: block;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.33;
    }

    @media (min-width: $lg) {
      font-size: 30px;
    }
  }

  &__address-block {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: $max-md) {
      justify-content: center;
    }
  }

  &__image {
    width: 30px;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__address {
    @media (min-width: $lg) {
      font-size: 20px;
    }
  }

  &__text {
    font-family: "Roboto";
    font-size: 14px;
    line-height: 1.33;

    @media (min-width: $md) {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
