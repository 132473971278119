@import 'src/app/styles/breakpoints';
.icon-title {
	display: flex;
	align-items: center;
	gap: 5px;
	font-family: var(--ubuntu);
	font-weight: 300;
	line-height: 1em;
	transition: opacity 150ms ease-in, transform 250ms ease-in;
	transform: scale(1) !important;
	min-width: 0;
	&[data-gap='12'] {
		gap: 12px;
	}
	&[data-color='gray'] {
		> svg {
			color: #7e7e7e;
		}
	}
	> svg {
		color: var(--white);
		min-width: 24px;
		width: 24px;
		height: 24px;
	}
}
