.profile {
	display: flex;
	gap: 20px;
	color: var(--white);
	$aside-width: 330px;
	@media screen and (max-width: 1920px) {
		gap: 15px;
	}
	@media screen and (max-width: 1440px) {
		$aside-width: 280px;
		@supports (min-height: 100dvh) {
			min-height: 100dvh;
		}
		min-height: 100vh;
	}
	@media screen and (max-width: 768px) {
		$aside-width: 250px;
	}
	&__aside {
		padding-top: 40px;
		position: sticky;
		top: var(--global-header-height);
		min-width: var($aside-width);
		background-color: #000000;
		overflow: hidden;
		z-index: 10;
		transition: transform 0.3s ease-in-out;
		@supports (height: 100dvh) {
			height: 100dvh;
		}
		height: 100vh;
		@media screen and (max-width: 1440px) {
			position: fixed;
			height: 100vh;
			padding-top: 20px;
			left: 0;
			z-index: 150;
			transform: translateX(-$aside-width);
			&.active {
				min-width: $aside-width;
				transform: translateX(0);
				.profile__aside__links {
					opacity: 1;
				}
			}
			&__links {
				gap: 5px;
				opacity: 0;
			}
		}
		&__links {
			display: flex;
			flex-direction: column;
			gap: 15px;
			transition: opacity 0.3s ease-in-out;
			&__item {
				padding-left: var(--main-x-padding);
				display: flex;
				gap: 10px;
				align-items: center;
				width: 100%;
				height: 60px;
				color: #ffffff;
				transition: all 0.3s ease-in-out;
				border-right: 2px solid #000000;
				&.active {
					background-color: #151515;
					border-color: var(--green);
					text-shadow: var(--yellow) 0 0 5px;
				}
				&:hover {
					color: #ffffff;
					background-color: #151515;
				}
			}
		}
	}
	&__content {
		flex-grow: 1;
		margin-top: 20px;
		width: calc(100% - $aside-width - var(--main-x-padding));
		padding-right: var(--main-x-padding);
		margin-bottom: 60px;
		@media screen and (max-width: 1440px) {
			width: calc(100% - var(--main-x-padding));
			padding-left: var(--main-x-padding);
		}
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
		z-index: 120;
		&.active {
			opacity: 1;
			visibility: visible;
		}
	}
	&__header-title {
		display: flex;
		align-items: center;
		gap: 10px;
		> h1 {
			font-size: 30px;
			@media screen and (max-width: 1920px) {
				font-size: 26px;
			}
			@media screen and (max-width: 1024px) {
				font-size: 24px;
			}
			@media screen and (max-width: 768px) {
				font-size: 20px;
			}
			font-weight: 500;
			text-transform: uppercase;
		}
	}
	&__sticky {
		padding: 10px 0;
		@media screen and (max-width: 1440px) {
			position: sticky;
			z-index: 10;
			top: var(--global-header-height);
			background-color: var(--graphite);
		}
	}
}
