.field-checkbox {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	user-select: none;
	font-size: 14px;
	width: fit-content;
	color: var(--white);
	input[aria-invalid='true'] {
		~ .field-checkbox__checkmark {
			border-color: var(--error);
		}
	}
	&__checkmark {
		position: relative;
		height: 20px;
		width: 20px;
		border-radius: 5px;
		border: 1px solid var(--white);
		transition: all 0.3s ease-in-out;
		&:after {
			content: '✓';
			font-size: 16px;
			transition: all 0.3s ease-in-out;
			position: absolute;
			opacity: 0;
			width: 5px;
			height: 10px;
			left: 25%;
		}
	}

	> input {
		position: absolute;
		height: 0;
		width: 0;
		&:checked ~ .field-checkbox__checkmark {
			&:after {
				opacity: 1;
			}
		}
	}
}
