.toggle-button {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	padding: 0.5rem 1rem;
	font-weight: 500;
	border: 2px solid transparent;
	transition: all 0.5s ease-in-out;
	border-radius: 5px;
	text-wrap: nowrap;
	white-space: nowrap;
	font-size: 1.25rem;
	&__icon {
		font-size: 1.2rem;
		transition: all 0.5s ease-in-out;
		&--close {
			opacity: 0;
			display: flex;
			align-items: center;
		}
	}
	&.active {
		border-color: var(--primary-green);

		background-color: var(--deep-black);
		.toggle-button__icon--close {
			opacity: 1;
		}
	}
	&:disabled {
		cursor: default;
		opacity: 0.7;
	}
}
