.game-data {
	flex-grow: 1;
	height: 50%;
	@media screen and (max-width: 1024px) {
		height: auto;
	}
	&__title {
		font-size: 20px;
		font-weight: 500;
	}
	&__content {
		display: flex;
		flex-direction: column;
		gap: 15px;
		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&__level {
				font-weight: 600;
			}
			> span:last-child {
				font-weight: 600;
			}
		}
	}
}
