@import 'src/app/styles/breakpoints';
.class-trainer {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	min-width: 0;
	line-height: 1em;
	font-weight: 500;
	text-transform: capitalize;
	> svg {
		min-width: 24px;
		width: 24px;
		height: 24px;
	}
}
