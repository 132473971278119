.workout-squad {
	display: flex;
	flex-direction: column;
	gap: 24px;
	min-width: 100%;
	transition: filter 0.2s ease-in-out;
	filter: blur(5px);
	padding-right: 5px;
	overflow-y: auto;
	overflow-x: hidden;
	&.active {
		filter: blur(0);
	}
	&__progress {
		position: sticky;
		top: 0;
		padding-top: 1px;
		background-color: var(--graphite);
		z-index: 4;
		font-size: 1.15rem;
	}
}
