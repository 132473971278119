.count-tip {
	position: absolute;
	width: 16px;
	height: 16px;
	background-color: var(--green-to-orange);
	border-radius: 50%;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	display: grid;
	align-content: center;
	top: -5px;
	right: -5px;
	&_position_bottom {
		bottom: -5px;
		top: unset;
	}
	&_position_top {
		bottom: unset;
		top: -5px;
	}
}
