.user-info {
	flex-grow: 1;
	&__wrapper {
		gap: 40px;
	}
	&__main {
		display: flex;
		align-items: center;
		gap: 40px;
		&__avatar {
			width: 256px;
			aspect-ratio: 1 / 1;
			border-radius: 12px;
			@media screen and (max-width: 1920px) {
				width: 200px;
			}
			@media screen and (max-width: 1024px) {
				width: 50%;
			}
			@media screen and (max-width: 768px) {
				width: 120px;
			}
			object-fit: cover;
		}
		&__name {
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 30px;
			@media screen and (max-width: 768px) {
				font-size: 14px;
				margin-bottom: 10px;
			}
		}
		&__contacts {
			display: flex;
			flex-direction: column;
			gap: 15px;
			@media screen and (max-width: 768px) {
				gap: 5px;
				.user-info__main__contacts__item {
					gap: 5px;
					> svg {
						width: 15px;
						height: 15px;
					}
				}
			}
			&__item {
				gap: 15px;
				> svg {
					width: 25px;
					height: 25px;
				}
			}
		}
	}
	&__about {
		display: flex;
		flex-direction: column;
		gap: 20px;
		&__list {
			display: flex;
			flex-direction: column;
			gap: 15px;
			&__item {
				display: flex;
				justify-content: space-between;
				&__about {
					display: grid;
					gap: 10px;
					&__text {
						color: #9e9e9e;
						word-break: break-all;
					}
				}
			}
		}
	}
}
