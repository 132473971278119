.notification-item {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--white-soft-hard);
	&__title {
		font-weight: 500;
		&[data-type='subscribe'] {
			color: var(--yellow);
		}
		&[data-type='change'] {
			color: var(--red);
		}
		&[data-type='workout'] {
			color: var(--light-green);
		}
	}
}
