// Fonts
$sans: 'Roboto', 'Arial', sans-serif;
$ubuntu: 'Ubuntu', $sans;
$roboto: 'Roboto', $sans;
$font-text: $ubuntu;
$font-title: $ubuntu;

// Color
$white: #fff;
$graphite: #292a2d;
$darkgreen: #337b3a;
$green: #72bf44;
$lightgrey: #dcdcdc;
$yellow: #faf62d;
$grey: #7e7e7e;
$red: #d91717;
$black: #000;

// Breakpoints
$mobile: '(max-width: 740px)';
$tablet: '(max-width: 1300px)';

$max-xs: 389 + px;
$xs: 390 + px;

$max-sm: 575 + px;
$sm: 576 + px;

$max-md: 743 + px;
$md: 744 + px;

$max-lg: 991 + px;
$lg: 992 + px;

$max-xl: 1199 + px;
$xl: 1200 + px;

$max-xxl: 1399 + px;
$xxl: 1400 + px;

// Other
$border-1: 1px solid $green;
$border-2: 2px solid $green;
$border-3: 3px solid $green;

$shadow-inset-2: inset 2px -2px 17px rgba(86, 255, 58, 0.5);
$shadow-inset-3: inset 3px -3px 23px rgba(86, 255, 58, 0.5);
$shadow-pseudo-inset: 0 0 17px 2px rgba(86, 255, 58, 0.5);
