@import "./src/assets/styles/variables";

.article {
  @media (min-width: $md) {
    display: flex;
    gap: 30px;
  }

  &__image {
    width: 100%;
    height: 250px;

    @media (max-width: $max-md) {
      margin-bottom: 30px;
    }

    @media (min-width: $md) {
      max-width: 280px;
      height: 280px;
    }

    @media (min-width: $lg) {
      max-width: 350px;
      height: 350px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    width: 100%;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: $green;
    padding-bottom: 24px;
  }

  &__date {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
  }

  &__content {
    font-size: 18px;
    max-height: 145px;
    transition: .2s ease;
    overflow: hidden;

    @media (min-width: $md) {
      max-height: 190px;
    }

    @media (min-width: $lg) {
      max-height: 215px;
    }
  }

  &__btn {
    display: block;
    margin-left: auto;
    margin-top: 15px;
    font-family: $font-text;
    font-size: 16px;
    color: $green;
    cursor: pointer;
  }
}

.expanded {
  max-height: 100%;
}
