@import "../../../assets/styles/variables";

// Birthday slider
.birthday-wrapper,
.birthday {
  height: 100%;
}

.birthday-slider {
  .swiper {
    padding-bottom: 40px;
    //height: 412px;

    @media (min-width: $md) {
      //height: 330px;
    }

    @media (min-width: $lg) {
      //height: 490px;
    }

    &-wrapper {
      align-items: stretch;
    }
  }

  &__item {
    height: auto;
  }
}

// Birthday item
.birthday {
  position: relative;
  padding: 20px 30px 30px 20px;
  border: $border-3;
  overflow: hidden;
  clip-path: polygon(100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%, 0 0);

  @media (min-width: $lg) {
    padding: 50px;
    clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0);
  }

  &-wrapper {
    position: relative;
  }

  &:after {
    content: '';
    transform: rotate(-45deg);
    position: absolute;
    bottom: 26px;
    right: -54px;
    display: block;
    width: 145px;
    height: 3px;
    background: $green;

    @media (min-width: $lg) {
      bottom: 46px;
      right: -24px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-family: $font-title;
    font-size: 16px;
    color: $green;
    line-height: 1;

    @media (min-width: $lg) {
      font-size: 20px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;

    @media (min-width: $lg) {
      font-size: 18px;
    }

    &-item {
      flex: 1 1 calc(50% - 10px);
    }
  }

  &__decor {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    border-right: $border-3;
    border-bottom: $border-3;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    overflow: hidden;

    @media (min-width: $lg) {
      width: 90px;
      height: 90px;
    }

    &:after {
      content: '';
      transform: rotate(-45deg);
      position: absolute;
      top: 14px;
      left: -36px;
      display: block;
      width: 125px;
      height: 3px;
      background: $green;

      @media (min-width: $lg) {
        top: 44px;
        left: -16px;
      }
    }
  }
}
