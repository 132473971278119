.main-info-settings {
	@media screen and (max-width: 1024px) {
		grid-area: primary;
	}
	&__title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	&__control {
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-size: 14px;
		font-weight: 600;
		> label {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		&-checkbox {
			flex-direction: row;
		}
	}
	.main-info-settings__control-select__control,
	.main-info-settings__control-select__menu {
		z-index: 11;
		box-shadow: none;
		background-color: var(--graphite);
		padding: 5px;
		border-radius: 5px;
		border: 1px solid #ffffff80;
		&[aria-invalid='true'] {
			border-color: crimson;
		}
		&:focus {
			background-color: #18191b;
		}
		&::placeholder {
			opacity: 0.6;
			font-size: 14px;
		}
		&:hover {
			border-color: inherit;
		}
	}
	.main-info-settings__control-select__option {
		background-color: inherit;
		transition: all 0.2s ease-in-out;
		&:hover {
			opacity: 0.8;
			cursor: pointer;
		}
		&--is-disabled:hover {
			opacity: 1;
			cursor: default;
		}
	}
	.main-info-settings__control-select__multi-value {
		background-color: #222222;

		border-radius: 5px;

		&__label {
			color: var(--white);
		}
		&__remove {
			transition: all 0.2s ease-in-out;
			&:hover {
				background-color: inherit;
				color: var(--red);
				cursor: pointer;
			}
		}
	}
}
