.slider {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	&__slides-list {
		display: flex;
		transition: transform 1s ease-in-out;
		width: 100%;
		transform: translateX(var(--translateX));
	}
	&-container__arrows {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}
	&:hover {
		.slider-container__arrows {
			opacity: 1;
		}
	}
}
