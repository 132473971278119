@import '../../assets/styles/variables';

// Training choose section
.select-training {
	position: relative;
	padding: 25px 20px;
	border: $border-2;
	clip-path: polygon(
		100% 0,
		100% calc(100% - 63px),
		calc(100% - 63px) 100%,
		0 100%,
		0 0
	);

	@media (min-width: $md) {
		display: flex;
		align-items: flex-start;
		gap: 20px;
		padding: 30px 25px;
	}

	@media (min-width: $lg) {
		padding: 45px;
		gap: 30px;
		clip-path: polygon(
			100% 0,
			100% calc(100% - 103px),
			calc(100% - 103px) 100%,
			0 100%,
			0 0
		);
	}

	// Decor style
	&:before {
		content: '';
		position: absolute;
		transform: rotate(-45deg);
		right: -14px;
		bottom: 29px;
		z-index: 1;
		width: 88px;
		height: 2px;
		background: $green;

		@media (min-width: $lg) {
			right: -22px;
			bottom: 49px;
			width: 145px;
		}
	}

	&-wrapper {
		position: relative;
		padding: 15px;

		@media (min-width: $lg) {
			padding: 28px 25px;
		}

		&__left-decor,
		&__right-decor {
			position: absolute;
			color: $green;

			& svg {
				display: block;
			}
		}

		&__left-decor {
			transform: rotate(90deg);
			top: -28px;
			left: 28px;

			@media (min-width: $lg) {
				top: -46px;
				left: 47px;
			}

			& svg {
				@media (max-width: $max-lg) {
					height: 110px;
					width: 55px;
				}
			}
		}

		&__right-decor {
			transform: scale(1, -1);
			right: 0;
			bottom: 0;

			& svg {
				@media (max-width: $max-lg) {
					width: 150px;
					height: 150px;
				}
			}
		}
	}

	&__preview,
	&__list {
		flex: 1 1 50%;
	}

	// Training list style
	&__list {
		position: relative;
		height: auto;

		@media (max-width: $max-md) {
			margin-bottom: 25px;
		}

		& .swiper-wrapper {
			display: block;
			transform: none !important;
		}

		&-item {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 12px;
			width: 100%;
			height: 45px;
			border: 1px solid $green;
			cursor: pointer;
			box-sizing: border-box;

			@media (min-width: $md) {
				height: 30px;
			}

			@media (min-width: $lg) {
				margin-bottom: 20px;
				height: 50px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:hover {
				background-color: $green;
			}

			p {
				font-family: $font-title;
				line-height: 1;

				@media (min-width: $lg) {
					font-size: 24px;
				}
			}

			&.swiper-slide-active {
				background-color: $green;
			}
		}

		& .swiper-pagination {
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			gap: 12px;
			height: 100%;

			@media (min-width: $lg) {
				gap: 20px;
			}

			&-bullet {
				display: block;
				width: 100%;
				margin: 0 !important;
				height: 45px;
				border: 0;
				border-radius: 0;
				background: transparent;
				box-shadow: none;

				@media (min-width: $md) {
					height: 30px;
				}

				@media (min-width: $lg) {
					height: 50px;
				}
			}
		}
	}

	// Training preview style
	&__preview {
		position: relative;
		z-index: 1;
		overflow: hidden;
		clip-path: polygon(
			100% 0,
			100% calc(100% - 50px),
			calc(100% - 50px) 100%,
			0 100%,
			0 0
		);

		@media (max-width: $max-md) {
			min-height: 250px;
		}

		@media (min-width: $lg) {
			clip-path: polygon(
				100% 0,
				100% calc(100% - 60px),
				calc(100% - 60px) 100%,
				0 100%,
				0 0
			);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

// Training info section
.training-info-section {
	margin-top: 40px;

	@media (min-width: $lg) {
		padding-left: 25px;
	}
}

.training-slider {
	&__item {
		width: 100%;
	}

	& .swiper-wrapper {
		max-width: none;
	}
}

.training {
	@media (min-width: $md) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: end;
		gap: 30px;
		grid-template-rows: 40px auto auto;
		grid-template-areas:
			'training-image training-title'
			//"training-image training-subtitle"
			'training-image training-schedule'
			'training-info training-info';
	}

	@media (min-width: $lg) {
		grid-template-rows: 24px auto 50px;
		align-items: start;
		grid-template-areas:
			'training-image training-title'
			//"training-image training-subtitle"
			'training-image training-info'
			'training-image training-schedule';
	}

	&__image {
		height: 570px;
		max-height: 280px;

		@media (max-width: $max-md) {
			margin-bottom: 30px;
		}

		@media (min-width: $md) {
			grid-area: training-image;
			max-height: 265px;
		}

		@media (min-width: $lg) {
			max-height: none;
		}
	}

	&__subtitle {
		@media (max-width: $max-md) {
			margin-bottom: 15px;
		}
	}

	&__title {
		@media (min-width: $md) {
			grid-area: training-title;
		}
	}

	&__subtitle {
		font-size: 18px;

		@media (min-width: $md) {
			grid-area: training-subtitle;
		}
	}

	&__info {
		@media (max-width: $max-md) {
			margin-bottom: 30px;
		}

		@media (min-width: $md) {
			grid-area: training-info;
		}
	}

	&__schedule {
		display: flex;
		justify-content: center;
		align-items: center;
		grid-area: training-schedule;
		font-family: $font-title;
	}
}
