@import "../../assets/styles/variables";

.review {
  @media (min-width: $md) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
  }

  @media (min-width: $lg) {
    gap: 42px;
  }

  &__header {
    @media (max-width: $max-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
  }

  &__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: $border-2;
    overflow: hidden;

    @media (min-width: $md) {
      width: 100px;
      height: 100px;
    }

    @media (min-width: $lg) {
      width: 120px;
      height: 120px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .rating {
    gap: 3px;
    pointer-events: none;

    @media (min-width: $md) and (max-width: $max-lg) {
      position: absolute;
      bottom: 10px;
      right: 7px;
    }

    @media (min-width: $lg) {
      margin-left: auto;
    }

    &__item {
      width: 18px;
      height: 18px;

      @media (min-width: $md) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__body {
    position: relative;
    padding: 10px;
    width: 100%;
    border: $border-2;
    clip-path: polygon(0 0, calc(100% - 70px) 0%, 100% 70px, 100% 100%, 70px 100%, 0% calc(100% - 70px));

    @media (min-width: $lg) {
      padding: 20px;
      clip-path: polygon(0 0, calc(100% - 80px) 0%, 100% 80px, 100% 100%, 75px 100%, 0% calc(100% - 85px));
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      transform: rotate(45deg);
      background: $green;
      width: 100px;
      height: 2px;
    }

    &:before {
      bottom: 32px;
      left: -16px;

      @media (min-width: $lg) {
        transform: rotate(48.5deg);
        bottom: 40px;
        left: -21px;
        width: 114px;
      }
    }

    &:after {
      top: 32px;
      right: -16px;

      @media (min-width: $lg) {
        top: 38px;
        right: -19px;
        width: 114px;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    padding-right: 70px;
    padding-left: 15px;
    font-size: 14px;
    font-family: $font-title;
    font-weight: 400;
    color: $white;
    line-height: 1;

    @media (min-width: $md) {
      padding-right: 75px;
    }

    @media (min-width: $lg) {
      gap: 50px;
      padding-left: 20px;
      font-size: 18px;
    }
  }

  &__author-name {
    font-size: 14px;
    font-weight: 400;

    @media (min-width: $lg) {
      font-size: 18px;
    }
  }

  &__text-content {
    padding-right: 30px;
    padding-left: 50px;
    font-size: 14px;
    opacity: .8;
    overflow: hidden;
    transition: height .2s ease;

    @media (min-width: $md) {
      margin-bottom: 30px;
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (min-width: $lg) {
      margin-bottom: 0;
      padding: 0 45px 0 55px;
      opacity: .5;
    }

    &.collapsing {
      margin-bottom: 0;
    }
  }

  &__show-more {
    display: block;
    margin-top: 14px;
    margin-left: auto;
    font-size: 12px;
    font-family: inherit;
    color: $green;

    @media (min-width: $md) {
      margin-bottom: 40px;
    }

    @media (min-width: $lg) {
      margin-bottom: 0;
    }
  }
}
