.profile-about {
	display: flex;
	flex-direction: column;
	gap: 30px;
	flex-grow: 1;
	@media screen and (max-width: 1920px) {
		font-size: 14px;
	}
	@media screen and (max-width: 768px) {
		font-size: 12px;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__content {
		display: flex;
		gap: 20px;
		@media screen and (max-width: 1024px) {
			flex-direction: column;
		}
		&__secondary {
			display: flex;
			flex-direction: column;
			gap: 15px;
			width: 50%;
			@media screen and (max-width: 1024px) {
				flex-direction: row;
				width: 100%;
			}
			@media screen and (max-width: 768px) {
				flex-direction: column;
			}
		}
	}
}
