.joinClassInfo-container {
	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: center;
	justify-content: space-between;
	position: relative;
	overflow-y: auto;
	z-index: 4;
	&__content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
		&__title {
			font-size: 28px;
			font-weight: 700;
			@media screen and (max-width: 768px) {
				font-size: 22px;
			}
		}
		&__subtitle {
			text-align: center;
			// margin-top: -10px;
		}

		&__list {
			display: flex;
			flex-direction: column;
			gap: 8px;
			&__info-subscribe {
				display: flex;
				align-items: center;
				gap: 20px;
				text-wrap: nowrap;
				> span:nth-child(1) {
					min-width: 110px;
				}
			}
			&__roles-counter {
				display: flex;
				gap: 40px;
			}
			&__role {
				display: flex;
				align-items: center;
				gap: 20px;
				text-wrap: nowrap;
				min-width: 165px;
				justify-content: space-between;
			}
		}
		&__error {
			text-align: center;
		}
	}

	&__link {
		cursor: pointer;
		display: block;
		&:hover {
			color: inherit;
			transform: scale(1);
		}
	}
}
