$breakpoints: (
	'xs': 0,
	'sm': 576px,
	'md': 768px,
	'lg': 992px,
	'xl': 1200px,
	'xxl': 1400px,
	'xxxl': 1650px,
);

@mixin sm {
	@media (max-width: map-get($breakpoints, 'sm')) {
		@content;
	}
}
@mixin md {
	@media (max-width: map-get($breakpoints, 'md')) {
		@content;
	}
}
@mixin lg {
	@media (max-width: map-get($breakpoints, 'lg')) {
		@content;
	}
}
@mixin xl {
	@media (max-width: map-get($breakpoints, 'xl')) {
		@content;
	}
}
@mixin xxl {
	@media (max-width: map-get($breakpoints, 'xxl')) {
		@content;
	}
}
@mixin xxxl {
	@media (max-width: map-get($breakpoints, 'xxxl')) {
		@content;
	}
}
@mixin hd {
	@media (min-width: map-get($breakpoints, 'xxxl')) {
		@content;
	}
}

@mixin breakpoint($bp: 0) {
	@media (max-width: $bp) {
		@content;
	}
}
