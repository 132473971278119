.slider-arrows {
  position: absolute;
  // top: calc(50% - 35px);
  bottom: 50%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  padding: 0 10px;

  &__right {
    transition: opacity 0.3s ease-in-out;
    transform: rotate(180deg);
    &:hover {
      opacity: 0.7;
    }
  }
  &__left {
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
}
