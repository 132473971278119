@import 'src/app/styles/breakpoints';
.products {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-inline: var(--main-x-padding);
	&__tabs {
		position: sticky;
		overflow-y: auto;
		z-index: 20;
		top: var(--global-header-height);
		display: flex;
		gap: 24px;
		background-color: var(--main-bg);
		height: var(--sub-header-height);
		align-items: center;
		scrollbar-color: transparent transparent;
	}
	&__content {
		min-height: calc(
			100vh - var(--global-header-height) - var(--sub-header-height)
		);

		display: flex;
		@include lg {
			justify-content: center;
		}
	}
	&__list {
		padding-bottom: 20px;
		display: flex;
		gap: 16px;
		overflow: auto;
		height: fit-content;
		@include lg {
			flex-direction: column;
			padding-bottom: 36px;
		}
	}
}
