.switch-theme {
	position: relative;
	width: 32px;
	height: 32px;
	--main-animation-length: 0.5s;
	--secondary-animation-length: 0.7s;
}
.moon-switch {
	position: absolute;
	top: 8px;
	left: 8px;
	transition: transform var(--main-animation-length) ease-in-out,
		color var(--main-animation-length) ease-in-out,
		opacity var(--main-animation-length) ease-in-out;
	opacity: 0;

	&.out {
		animation: moon-out var(--main-animation-length) ease-in-out;
		transform: scale(0);
	}
	&.active {
		opacity: 1;
		animation: moon var(--main-animation-length) ease-in-out;
	}
}
.sun-switch {
	position: absolute;
	top: 8px;
	left: 8px;
	transition: transform var(--main-animation-length) ease-in-out,
		opacity var(--main-animation-length) ease-in-out;
	opacity: 0;
	&.out {
		animation: sun-out var(--main-animation-length) ease-in-out;
	}
	&.active {
		opacity: 1;
		animation: sun var(--main-animation-length) ease-in-out;
		-webkit-animation: sun var(--main-animation-length) ease-in-out;
	}
}
@keyframes sun-out {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes moon-out {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(-60deg) scale(0.5);
	}
	100% {
		transform: rotate(-60deg) scale(0);
	}
}
@keyframes moon {
	0% {
		transform: rotate(-60deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
@keyframes sun {
	0% {
		transform: rotate(60deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
