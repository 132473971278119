.field-control {
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-size: 14px;
	font-weight: 600;
	// transition: transform 150ms ease-in-out;
	&__label {
		position: relative;
	}

	&__input {
		z-index: 2;
		width: 100%;
		background-color: var(--main-bg);
		padding: 15px 16px;
		border-radius: 8px;
		border: 1px solid var(--white-soft);
		// transition: transform 250ms ease, background-color 0.25s ease;
		transform: translate(0);
		color: var(--white);
		height: 40px;
		&[aria-invalid='true'] {
			~ .label {
				color: var(--red) !important;
			}
			border-color: var(--red);
		}
		&::placeholder {
			opacity: 0;
			pointer-events: none;
		}
		&::-webkit-calendar-picker-indicator {
			filter: invert(1);
			display: none;
		}
		&:focus ~ .label,
		&:not(:placeholder-shown) ~ .label {
			font-weight: 400;
			transform: translate(-8px, -19px);
			font-size: 12px;
			padding-inline: 8px;
			color: var(--white-soft);
		}
	}
	.label {
		z-index: 3;
		position: absolute;
		font-weight: 500;
		color: var(--gray);
		top: 10px;
		left: 16px;
		background-color: var(--main-bg);
		border-radius: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		transition: all 150ms ease-out;
		&:hover {
			cursor: text;
		}
	}
	&__eye {
		position: absolute;
		right: 15px;
		top: 8px;
		height: 25px;
		padding-left: 10px;
		padding-top: 1px;
		background-color: var(--main-bg);
		> svg {
			height: 20px;
			width: 20px;
			color: var(--white);
		}
	}
}
